.calligo__header-carousals {
  /* padding: 20px 10px; */
  color: var(--color-footer);
}

/* .carousel-item { 
 
  
}  */

.calligo__header-overlay button {
  border: 2px solid white;
  margin: 1rem 4rem;
  color: white;
  padding: 10px;
  font-size: 18px;
  width: 160px;
  border-radius: 500px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  /* text-decoration: none; */
}

.calligo__header-overlay button:hover {
  letter-spacing: 1px;
  color: blue;
  background-color: aliceblue;

}

.calligo__header-carousals img {
  width: 100%;
  height: 430.633px;
  margin: auto;
  position: relative;

}

.calligo__header-overlay link {
  text-decoration: none;
}

.calligo__header-overlay {
  color: white;
  font: var(--font-family);
  font-weight: 600;
  font-size: larger;
  position: absolute;
  top: 10%;
  width: 46%;
  right: 45%;
  bottom: 90%;
  vertical-align: top;

  @media (max-width: 750px) {
    top: 50%;
    bottom: 50%;
    font-size: medium;
  }
}

.calligo__header-overlay p {
  font-size: 18px;
  text-align: left;
  margin: 1rem 0rem;
  /* margin: 1rem 4rem; */

}


.calligo__header-overlay b {
  font-size: xx-large;
  text-decoration: none;
  /* margin: 0rem 4rem; */

}

@media screen and (max-width: 1050px) {
  .calligo__header-overlay p {
    font-size: 14px;

  }

  .calligo__header-overlay b {
    font-size: larger;
  }

  .calligo__header-carousals img {
    height: 360px;

  }

  .calligo__header-overlay button {
    margin: 0rem 4rem;
    padding: 3px;
    font-size: 12px;
    width: 100px;
    border-radius: 250px;

  }
}

@media screen and (max-width: 700px) {
  .calligo__header-overlay p {
    font-size: 15px;

  }

  .calligo__header-overlay b {
    font-size: medium;
  }

  .calligo__header-overlay button {
    margin: 0rem 4rem;
    padding: 2px;
    font-size: 10px;
    width: 90px;
    border-radius: 250px;

  }
}

@media screen and (max-width: 550px) {

  .calligo__header-overlay p {
    font-size: 18px;
    /* line-height: 1.6875rem; */
    margin-top: 0;
    margin-bottom: 1rem;
    word-break: break-word;
    text-align: center;
    margin: 1rem 1rem;

  }

  .calligo__header-overlay {
    width: 100%;
    right: 0%;
    bottom: 0%;
    top: 10%;
    text-align: center;
  }

  .calligo__header-overlay b {
    font-size: x-large;
    color: rgb(255, 255, 255);
  }

  .calligo__header-carousals img {
    height: 500px;
    filter: blur(3px);

  }

  .calligo__header-overlay button {
    margin: 1rem 4rem;
    padding: 4px;
    font-size: 14px;
    width: 90px;
    border-radius: 250px;
    color: blue;
    background-color: aliceblue;

  }
}