.footer {
    position: relative;
    background: radial-gradient(circle at 3% 25%, rgb(0, 40, 83) 0%, rgba(4, 12, 24, 1) 100%);
    font-family: var(--font-family);
    color: #dddddd;
    padding: 45px 15px;
    /* Adjusted padding */
}

.footer .calligo-footerabout h3,
.footer .calligo-footerlink h3,
.footer .calligo-footercontact h3 {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #0085ff;
    text-align: start;
}

.footer .calligo-footercontact p {
    font-size: 15px;
}

.footer .calligo-footercontact {
    line-height: 2;
    /* Adjust line height to reduce space between paragraphs */
}

.footer .calligo-footercontact p {
    margin: 0;
    /* Remove default margin */
    font-size: 15px;
    /* Adjusted font size */
}


.footer .calligo-footerabout p {
    font-size: 15px;
    
    /* Adjusted font size */
}

.footer .calligo-footerlink a {
    display: block;
    margin-bottom: 10px;
    color: #dddddd;
    transition: .3s;
    text-transform: capitalize;
    text-decoration: none;
}

.footer .calligo-footerlink a:hover {
    color: #0085ff;
    letter-spacing: 1px;
}

.footer .calligo-footersocial a {
    display: inline-block;
    margin-right: 1rem;
    /* Adjusted margin */
}

.footer .calligo-footersocial a {
    margin-right: 10px;
    /* Adjusted margin */
    font-size: 24px;
    color: #0085ff;
}

.footer .calligo-footermenu .f-menu {
    padding: 10px 0;
    font-size: 16px;
    /* Adjusted font size */
    text-align: center;
    text-decoration: none;
    border-top: 1px solid rgba(255, 255, 255, .1);
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.footer .calligo-footermenu .f-menu a {
    color: #dddddd;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, .3);
}

.footer .calligo-footermenu .f-menu a:hover {
    color: #0085ff;
}

.footer .footer-menu .f-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

.footer .copyright p {
    margin: 0;
}

.footer .copyright p a {
    color: #0085ff;
    font-weight: 500;
    letter-spacing: 1px;
}

.footer .copyright p a:hover {
    color: #ffffff;
}

.calligo-footermenu a {
    text-decoration: none;
}

@media (max-width: 768px) {
    .footer .footer-menu .f-menu {
        font-size: 14px;
        /* Adjusted font size */
    }

    .footer .calligo-footerabout p {
        font-size: 14px;
        /* Adjusted font size */
    }

    .footer .calligo-footersocial a i {
        font-size: 16px;
        /* Adjusted font size */
    }
}


@media (max-width: 500px) {
    .footer .calligo-footerabout p {
        font-size: 18px;
        text-align: start;
        padding: 20px 0px;
        /* Adjusted font size */
    }

    .footer .calligo-footersocial a {
        font-size: 24px;        
        /* padding: 10px 10px; */
        /* Adjusted font size */
    }

    .footer .calligo-footerlink {
        font-size: 18px;
        padding: 20px 0px;

    }

    .footer .calligo-footercontact p {
        padding: 5px 0px 10px 0px;
    }

    
}