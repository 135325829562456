.calligo__heading text-md{
    color: #093c71;
    text-align: center;
    /* font-weight: 100; */
    line-height: 43.2px;
    height: 43.2px;
    /* width: 691.2px; */
    margin: 0 0 20px 0;
    display: block;
    
}

.certification__image{
    width: 400px;
}

.calligo__wrapper{
    display: block;
    margin: 0 0 20px 0;
    padding: 15px 15px 0 15px;
    font-family: var(--font-family);
    text-align: center;
    
}

.calligo__wrapper p{
    margin: 0 0 20px 0;
    text-align: justify;
    padding: 35px 15px 0 15px;
}

.calligo__wrapper h2{
    color: #093c71;
    margin: 0 0 20px 0;
    padding: 35px 15px 0 15px;
    font-size: 50px;
}

.calligo__wrapper h3{
    color: #093c71;
    padding: 35px 15px 0 15px;
    margin: 0 20px 0 0;
    text-align: left;
}
.calligo__wrapper-figure{
    width: 100%;
   padding: 55px 15px 0 15px;
    
}

.calligo__wrapper-figure h2{
    color: #093c71;
    text-align: center;
    font-weight: 700;
    line-height: 43.2px;
    font-family: var(--font-family);
    margin: 0 0 20px 0;
    font-size: 36px;
    display: block;
}

.calligo__wrapper-figure p{
    display: flexbox;
}

.calligo__aboutus {
	font-size: 18px;
    
    line-height: 29.25px;
    /* height: 1194.51px; */
    width: auto;
    /* margin: 0px 0px -15px 0px;
    margin: 18px; */

    padding: 35px 10px 10px 15px;
    display: block;
    text-align: center;
    align-items: center;
    /* background: rgba(255, 255, 255, 0.375); */
    /* background: transparent; */
    background-color: rgba(255,255,255, 1);
    box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
    /* border-radius: 2rem; */
    border: 1px solid rgba(255, 255, 255, 0.125);
}

/* .calligo__aboutus_section {
    --dark-color: hsl(var(--hue), 100%, 9%);
  --light-color: hsl(var(--hue), 95%, 98%);
  --base: hsl(var(--hue), 95%, 50%);
  --complimentary1: hsl(var(--hue-complimentary1), 95%, 50%);
  --complimentary2: hsl(var(--hue-complimentary2), 95%, 50%);
  --bg-gradient: linear-gradient(
    to bottom,
    hsl(var(--hue), 95%, 99%),
    hsl(var(--hue), 95%, 84%)
  );
} */

