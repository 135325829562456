 /* . {
  overflow-x: hidden;
 } */
 .calligo-aboutus {

   /* padding: 60px 0 40px 0; */
   padding: 20px 0;
   font-family: var(--font-family);
   position: relative;

   z-index: 9;
 }

 .calligo--brand-color {
   background: radial-gradient(circle at 3% 25%, rgb(0, 40, 83) 0%, rgba(4, 12, 24, 1) 100%);

 }

 .calligo-border {
   width: 160px;
   height: 1px;
   background: #82ccdd;
   margin: 20px auto;
   /* padding: 0px 20px; */
 }

 /*   
  .::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: lightgrey;
    z-index: 9;
  } */
 .col-md-4 {
   padding: 30px 10px 30px 10px;
 }

 .container1 {
   position: relative;
   z-index: 10;
 }

 .container1 p {
   font-size: 20px;
 }

 .about-col {
   background: #fff;
   border-radius: 0 0 4px 4px;
   box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
   /* margin-bottom: 20px; */
   height: 350px;
   padding: 30px 10px 30px 10px;
 }

 .about-col .img {
   position: relative;

 }

 .about-col .img img {
   border-radius: 4px 4px 0 0;
 }

 .about-col .icon {
   width: 64px;
   height: 64px;
   /* padding-top: 8px; */
   text-align: center;
   position: relative;
   /* background-color: #3C5A99; */
   border-radius: 50%;
   text-align: center;
   border: 4px solid #fff;
   left: calc(50% - 32px);
   bottom: -30px;
   transition: 0.3s;
 }

 /*   
   .about-col i {
    font-size: 36px;
    line-height: 1;
    color: #fff;
    transition: 0.3s;
  } */

 .about-col:hover .icon {
   background-color: #fff;
 }

 .about-col:hover i {
   color: #3C5A99;
 }

 .about-col h2 {
   color: #000;
   text-align: center;
   font-weight: 700;
   font-size: 20px;
   padding: 0;
   margin: 40px 0 12px 0;
 }

 .about-col h2 a {
   color: #000;
 }

 .about-col h2 a:hover {
   color: #3C5A99;
 }

 .about-col p {
   font-size: 14px;
   line-height: 24px;
   color: #333;
   margin-bottom: 0;
   padding: 0 20px 20px 20px;
 }

 .container1 p {
   padding: 20px 40px;
 }

 .container1 h3 {
   text-align: center;
   color: #093c71;
   text-transform: uppercase;
   font-size: 30px;
   padding: 5px 0;
 }

 #call-to-action {
   background: linear-gradient(rgba(21, 86, 112, 0.1), rgba(0, 0, 0, 0.1));
   background-size: cover;
   padding: 60px 0;
 }

 #call-to-action h3 {
   color: black;
   font-size: 28px;
   font-weight: 700;
 }

 #call-to-action p {
   color: black;
 }

 #call-to-action .cta-btn {
   /* font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 25px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid #fff;
    color: #fff;
    text-decoration: none; */
   border: 2px solid #093c71;
   margin: 1rem 4rem;
   color: #093c71;
   padding: 10px;
   font-size: 18px;
   width: 160px;
   border-radius: 500px;
   cursor: pointer;
   background-color: transparent;
   transition: all 0.2s ease-in-out;
   text-decoration: none;

 }


 #call-to-action .cta-btn:hover {
   background: #093c71;
   color: #fff
     /* border: 2px solid #093c71; */
 }

 @media screen and (max-width:500px) {
   .container1 p {
     padding: 20px 20px;
     text-align: justify;
     /* font-size: 18px; */
   }
   
 }