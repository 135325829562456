.calligo__testimonal {
  padding: 60px 10px 60px 15px;
  align-items: center;
    /* background: rgba(255, 255, 255, 0.375); */
    /* background: transparent; */
    background-color: rgba(255,255,255, 1);
    box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
    /* border-radius: 2rem; */
    border: 1px solid rgba(255, 255, 255, 0.125);
    /* margin-top: 30px;
    margin-bottom: 30px;
    margin: 18px; */
    width: auto;
    display: flex;
  /* flex-wrap: wrap; */
    /* height: 600px ; */
    /* color: #000; */
}
.calligo__testimonal-wrapper {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.calligo__testimonal-title {
  color: #093c71;
  text-align: center;
  height: 43.2px;
  width: 100%;
  display: block;
  font-size: 50px;
  margin: 0 20px 50px 0;
  padding: 15px 15px 15px 15px;
  font-family: var(--font-family);
  line-height: 29.25px;
  
  
}
/* .calligo__testimonal-end{
  background-color: white;
  padding: 0.5px;
} */
.calligo__testimonal-wrap {
  width: 100%;
  
  flex: 1 0 33.3333%;
  float: left;
  margin-bottom: 3.5em;
  font-family: var(--font-family);
  
  /* text-align: center; */

  position: relative;
  /* background: #ecf0f1; */
  /* padding-top: 3em;
  padding-left: 1.5em;
  padding-bottom: 2.5em;
  padding-right: 1.5em; */
  /* border-radius: 3px;   */
  /* box-shadow: 0 1px 0 #bdc3c7; */
  /* background-color: rgba(255,255,255, 0.6); */
  /* box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1); */
  /* border-radius: 2rem; */
  border: 1px solid rgba(255, 255, 255, 0.125);
  /* margin:10px 10px 0px 0px; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.calligo__testimonal-wrap:before {
  content: "\201D";
  display: block;
  font-size: 5em;
  line-height: 1em;
  color: #093c71;
}

.calligo__testimonal-wrap img {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  width: 64px;
  height: 64px;
  margin: auto;
  border-radius: 50%;
  max-width: 100%;
  box-shadow: 0 0 0 4px #bdc3c7;
}

.calligo__testimonal-content {
  /* font-size: 1.120em; */
  font-size: medium;
  font-weight: 400;
  line-height: 1.5;
  color: #093c71;
  margin-bottom: 1em;
}

.calligo__testimonal-author {
  font-size: 1em;
  font-weight: 800;
  /* font-weight: bolder; */
  color: #093c71;
  text-transform: uppercase;
}

/* if screen greater than 768px */
@media screen and (min-width: 768px) {
  .calligo__testimonal-wrap {
    width: 33.3333%;
    /* height: 600px; */
    /* margin-bottom: 0; */
    border-right: 1px solid #ddd;
  }

  .calligo__testimonal-wrapper {
    display: block;
    /* flex-wrap: wrap; */
  }
  .calligo__testimonal-wrap:last-child {
    border-right: 0;
  }
  .calligo__testimonal-title {
    font-size: 50px;
  }
}

@media screen and (max-width: 550px) 
{
  .calligo__testimonal{
    flex-wrap: wrap;
    text-align: center;

  }
}
@media screen and (min-width: 360px) {
  .calligo__testimonal-wrap {
    /* width: 33.3333%; */
    /* height: 600px; */
    /* margin-bottom: 0; */
    
    border-right: 1px solid #ddd;
  }

  .calligo__testimonal-wrapper {
    display: block;
    /* flex-wrap: wrap; */
  }
  .calligo__testimonal-wrap:last-child {
    border-right: 0;
  }
  .calligo__testimonal-title {
    font-size: 50px;
  }
  .calligo__testimonal-content{
    display: flex;
    /* font-size: 1.120em; */
    flex-wrap: wrap;
  }

}