* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    /* overflow-x: hidden; */
    /* background-color: aqua; */
    /* width: 100%; */
    
    
}
.App {
    background-color: rgba(255,255,255, 0.5);
    /* background: #093c71; */
    background: radial-gradient(circle at 3% 25%, rgb(0, 40, 83) 0%, rgba(4, 12, 24, 1) 100%);
    overflow-x: hidden;

    
}
body {
    background: var(--color-bg);

}

a{
    color: unset;
    text-decoration: none;
}

.gradient__bg{
    background: radial-gradient(circle at 3% 25%, rgb(0, 40, 83) 0%, rgba(4, 12, 24, 1) 100%);
    /* padding: 0; */
}

.gradient__text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

.section_padding{
    padding: 4rem 6 rem;
}

.section_margin{
    padding: 4rem 6 rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @media screen and (max-width: 700px){
    .section_padding{
        padding: 4rem;
    }
    
    .section_margin{
        padding: 4rem;
    }
  }

  @media screen and (max-width: 550px){
    .section_padding{
        padding: 2rem;
    }
    
    .section_margin{
        padding: 2rem;
    }
  }