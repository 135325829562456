@import url('https://fonts.googleapis.com/css?family=Mukta+Mahee:200,400');

.calligo__contactus{
    font-family: var(--font-family);
    /* background: radial-gradient(circle at 3% 25%, rgb(0, 40, 83) 0%, rgba(4, 12, 24, 1) 100%); */
    /* background: var(--color-footer); */
    /* margin: auto; */
    background: radial-gradient(circle at 3% 25%, rgb(0, 40, 83) 0%, rgba(4, 12, 24, 1) 100%);

}
.container {
  margin-left: auto;
  margin-right: auto;
  padding: 2em;
  
  
}

.title {
  margin-bottom: 30px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 26px;
  color: #fff;
  text-align: center;
  /* padding: 1rem 0rem; */
}

.wrapper {
  background: #fff;
  /* border-radius: 5px; */
  /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1); */
  padding: 27.5px;
  background-color: rgba(255,255,255, 1);
  box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.125);
  
  
}

#contactForm {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    'name'
    'email'
    'company'
    'phone'
    'message';
}

.required-field {
  grid-column: 1 / 2;
}

.submit-button {
  grid-column: 2 / 3;
}

.name-field {
  grid-area: name;
  grid-column: 1 / 3;
}

.email-field {
  grid-area: email;
  grid-column: 1 / 3;
}

.company-field {
  grid-area: company;
  grid-column: 1 / 3;
}

.phone-field {
  grid-area: phone;
  grid-column: 1 / 3;
}

.message-field {
  grid-area: message;
  grid-column: 1 / 3;
}

/* FORM STYLES */

.contact-us {
  margin: 0 0 30px;
  font-family: 'Mukta Mahee', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #5dc3f2;
}

.contact form {
  border: 0;
}

.contact form label {
  display: block;
}

.contact form p {
  margin: 0;
  font-family: 'Mukta Mahee', sans-serif;
  font-weight: 200;
  font-size: 12px;
  color: rgba(74, 86, 96, 1);
}

.contact form input,
.contact form textarea {
  font-family: 'Mukta Mahee', sans-serif;
  font-weight: 200;
  font-size: 14px;
  width: 100%;
  padding: .4em .8em;
  background: rgba(249, 250, 250, .5);
  border: 1px solid rgba(74, 86, 96, .1);
  border-radius: 2.5px;
  outline-color: #5dc3f2;
}

.contact form button {
  font-family: 'Mukta Mahee', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  background: #5dc3f2;
  width: 90px;
  height: 30px;
  border: 0;
  border-radius: 15px;
}

.submit-button {
  align-self: center;
  justify-self: end;
}

.contact form button:hover,
.contact form button:focus {
  background: #49b0e7;
  color: #fff;
  outline: 0;
  transition: background-color 0.3s ease-out;
}

.required-field {
  font-family: 'Mukta Mahee', sans-serif;
  font-weight: 200;
  font-size: 10px;
  color: rgba(74, 86, 96, 0.75);
  align-self: center;
  justify-self: start;
}

span {
  color: #5dc3f2;
}

.alert {
  margin-bottom: 1em;
  padding: 10px;
  background: #49b0e7;
  font-family: 'Mukta Mahee', sans-serif;
  font-size: 14px;
  text-align: center;
  color: #fff;
  border-radius: 2.5px;
  display: none;
}

/* LARGE SCREENS */

@media (min-width: 500px) {
  #contactForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'name company'
      'email phone'
      'message message';
  }

  .name-field {
    grid-area: name;
    grid-column: 1 / 2;
  }

  .email-field {
    grid-area: email;
    grid-column: 1 / 2;
  }

  .company-field {
    grid-area: company;
    grid-column: 2 / 3;
  }

  .phone-field {
    grid-area: phone;
    grid-column: 2 / 3;
  }

  .message-field {
    grid-area: message;
    grid-column: 1 / 3;
  }
}