#calligo-call-to-action {
    background: linear-gradient(rgba(21, 86, 112, 0.1), rgba(0, 0, 0, 0.1));
    background-size: cover;
    padding: 60px 0;
  }
  
  #calligo-call-to-action h3 {
    color: black;
    font-size: 28px;
    font-weight: 700;
  }
  
  #calligo-call-to-action p {
    color: black;
  }
  
  #calligo-call-to-action .calligo-cta-btn {
    /* font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 25px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid #fff;
    color: #fff;
    text-decoration: none; */
    border: 2px solid #093c71;
    margin: 1rem 4rem;
    color: #093c71;
    padding: 10px;
    font-size: 18px;
    width: 160px;
    border-radius: 500px;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
  }

  /* .calligo-cta-btn {
    padding: 10%;
  } */
  #calligo-call-to-action .calligo-cta-btn:hover {
    background: #093c71;
    color:#fff
    /* border: 2px solid #093c71; */
  }
 

  @media screen and (max-width:500px) {
    .cta-btn a {
      padding: 20px;
    }
  }