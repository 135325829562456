.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.calligo-services-section{

    padding: 60px 0;
    font-family: var(--font-family);
    
  }
  .calligo-inner-width{
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0 20px;
    overflow: hidden;
  }
  .calligo-section-title{
    text-align: center;
    color: #093c71;
    text-transform: uppercase;
    font-size: 30px;
  }
  .calligo-border{
    width: 160px;
    height: 1px;
    background: #82ccdd;
    margin: 20px auto;
  }
  .calligo-services-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .calligo-service-box{
    max-width: 33.33%;
    padding: 20px;
    text-align: center;
    color: #ddd;
    cursor: pointer;

  }
  
  .calligo-service-icon{
    display: inline-block;
    width: 70px;
    height: 70px;
    color: #82ccdd;
    margin-bottom: 30px;
    margin-top: 16px;
    transition: 0.3s linear;
  }
  .calligo-service-icon i, .calligo-service-icon img{
    line-height: 70px;
    width: 70px;
    height: 70px;
    transition: transform 0.2s;
    font-size: 80px;
  }
  .calligo-service-box:hover .calligo-service-icon img {
    color: #ddd;
    transform: scale(1.2);
  }
  .calligo-service-title{
    font-size:  1.0em;
    color: #093c71;
    text-transform: uppercase;
    margin-bottom: 10px;
    
  }
  .calligo-service-desc{
    font-size:  1.0em;
    color: black;
    text-align: justify;
  }
  
  @media screen and (max-width:960px) {
    .calligo-service-box{
      max-width: 45%;
    }
  }
  
  @media screen and (max-width:768px) {
    .calligo-service-box{
      max-width: 50%;
    }
  }
  
  @media screen and (max-width:480px) {
    .calligo-service-box{
      max-width: 100%;
    }
    
  }
  

   

