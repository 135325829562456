/* 
.callligo__custom_heading{
    font-size: 48px;
    color: #ffffff;
    text-align: center;
    line-height: 78px;
    height: 78px;
    width: 100%;
    display: block;
    box-sizing: border-box; 
    word-wrap: break-word;
    padding: 1rem 0rem;
    margin: auto;
}

.callligo__custom_heading_s{
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 20px; 
    height: 20px;
    width: 160px;  

    display: block;
    box-sizing: border-box; 
    word-wrap: break-word;
    margin: auto;
    padding: 1rem 0rem;

    
}

.calligo_wrapper{
    background-color: radial-gradient(circle at 3% 25%, rgb(0, 40, 83) 0%, rgba(4, 12, 24, 1) 100%);
    font-size: 24px;
    line-height: 39px; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 2rem;
    font-family: var(--font-family);

}

@media screen and (max-width: 1080px) {
    .callligo__custom_heading{
        font-size: 38px;
    }
    .callligo__custom_heading_s{
        font-size: 15px;
        width: 160px;  
    }
}
@media screen and (max-width: 1050px){
    .calligo_wrapper{
        display: block;
    }
}
@media screen and (max-width: 700px) {
    .calligo_wrapper{
        display: block;
    }
    
}

@media screen and (max-width: 550px) {
    .calligo_wrapper{
        display: block;
        margin: auto;
    }
    
} */

.brand-carousel {
    background-color: #002853e5;
    overflow-x: hidden;
}
.single-logo img {
    width: 250px;
    padding: 30px;
    
    /* margin: 30px; */
}

.calligo-brand-header {
    color: white;
    text-align: center;
    height: 43.2px;
    width: 100%;
    display: block;
    font-size: 50px;
    margin: 0 0px 50px 0;
    padding: 60px 15px 15px 15px;
    font-family: var(--font-family);
    line-height: 29.25px;
}

.brand-carousel {
    width: 100%;
    
}

.calligo-brand-header {
    text-align: center;
    margin-bottom: 50px;
}

.single-logo {
    width: 100%;
    height: 150px;
    /* Adjust the height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.single-logo img {
    width: 250px;
    height: 100%;
    object-fit: contain;
}

.single-logo-lazer img{
    height: 200px;
    padding-bottom: 50px;
    padding-left: 50px;
    /* overflow: hidden; */

    
}
@media screen and (max-width: 500px) {
    .brand-carousel {
        padding: 0 80px;
        /* margin: auto; */

    }
    .calligo-brand-header{
        font-size: 30px;

    }
}