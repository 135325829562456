@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: #f4f5f6;
  /* --gradient-text: linear-gradient(1deg, rgba(30, 210, 244, 1) 0%, rgba(234, 252, 64, 1) 100%); */
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);

  /* --color-bg: #2b2b3a; */
  --color-bg: #F8F9F5;
  --color-footer: #002853;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;

  --font-size-base: 0.925rem;
  --base-color: #66f;
  --text-dark: #3c4250;
  --border-color: #eceef3;

  --navbar-body-color: #fff;
  --navbar-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.035);
  --navbar-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --navbar-padding-y: 1rem;
  --navbar-padding-x: 1.15rem;

  --navbar-link-hover: --base-color;
  --navbar-link-padding-y: 0.5rem;
  --navbar-link-padding-x: 1rem;

  --navbar-dropdown-padding-y: 0.5rem;
  --navbar-dropdown-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  --navbar-dropdown-radius: 0.75rem;

  --navbar-dropdown-item-padding-y: 0.25rem;
  --navbar-dropdown-item-padding-x: 1rem;

  --navbar-dropdown-separator-margin-y: 9px;
  --navbar-dropdown-separator-color: var(--border-color);

}