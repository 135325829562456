.menu-links {
  text-decoration: none;
  color: #fff;

}
.navbar-section{
  position: fixed;
}
.menu-links:hover {
  color: #0085ff;
  /* letter-spacing: 1px; */
}

/* .dropdown {
    position: relative;
    text-decoration: none;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    z-index: 1;
    text-decoration: none;

  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    animation: slide-down 0.5s ease;
  } */

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background: var(--color-footer);
  min-width: 200px;
  z-index: 9999;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
  margin: 2px -80px;
  
}

.dropdown-content a {
  color: white;
  padding: 10px 0;
  text-decoration: none;
  display: block;
  font-size: 16px;
}

.dropdown:hover .dropdown-content {
  display: block;
  animation: slide-down 0.5s ease;
  
  
}

.calligo__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: fixed; */
  /* background: var(--color-footer); */
  padding: 2rem 6rem;
  /* z-index: 9999; */
  
}

.calligo__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.calligo__navbar_logo {
  margin-right: 2rem;
}

.calligo__navbar-links_logo img {
  width: 200px;
  height: 68.5667px;
}

.calligo__navbar-links_container {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 200px;
  /* padding: 0rem 50rem; */


}

.calligo__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.calligo__navbar-links_container p,
.calligo__navbar-sign p,
.calligo__navbar-menu_container p {
  color: #fff;
  text-decoration: none;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
  padding: 0 0 0 20px;

}

.calligo__navbar-sign button,
.calligo__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #FF4820;
  font-family: var(--font-family);
  font-weight: 500;
  left: 1153px;
  top: 55px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.calligo__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
  position: absolute;
  /* left: 10px; */
  right: 10%;
  z-index: 999;

}

.calligo__navbar-menu svg {
  cursor: pointer;

}

.calligo__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.calligo__navbar-menu_container p {
  margin: 1rem 0;
}

.calligo__navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1080px) {
  .calligo__navbar-links_container p {
    font-size: medium;
    margin: 0rem 0.47rem;

  }
}

@media screen and (max-width: 1050px) {
  .calligo__navbar-links_container {
    display: none;
  }

  .calligo__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .calligo__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) 
{
  .calligo__navbar {
    padding: 2rem;
  }

  .calligo__navbar-sign {
    display: none;
  }

  .calligo__navbar-menu_container {
    top: 20px;
  }

  .calligo__navbar-menu_container-links-sign {
    display: block;
  }
  .dropdown-content{
    visibility: hidden;
  }
}

.menu-links {
  color: #ffffff; /* Adjust text color */
  text-decoration: none;
  /* font-weight: bold;
  text-transform: uppercase; */
  transition: color 0.3s;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 999;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 200px;
  z-index: 1;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); /* Adjust dropdown shadow */
  padding: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  padding: 8px 0;
  text-decoration: none;
  display: block;
}
